import escudo from './images/escudo.png';
import aviso from './images/avisoBlueCoin.png';

export const Header = () => {
  return (
    <div className='header'>
        <img src={aviso} height='90vw' alt='' className='bluecoin01'/>
        <span className='frase'>"WHERE YOUR PASSION LIVES"</span>
        <img src={escudo} height='130vw' alt='' className='escudo01'/>
    </div>

  );}