import aviso from './images/avisoBlueCoin.png';

export const Footer = () => {
  return (
      <div className='footer'>

        <img src={aviso} height='40em' alt='' className='bluecoin02'/><span className='frase'>"WHERE YOUR PASSION LIVES"</span>
        <ul>
          <li>About</li>
          <li>Politics</li>
          <li>Terms</li>
        </ul></div>);}
        