import escudo from './images/escudo.png';

export const Content = () => {

  return (
    <>
        <divi>
        <img src={escudo} alt='' className='escudo02'/>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        </divi>
    </>
  );
}
