import {Menu} from './menu.jsx';
import {Content} from './content.jsx';

export const Center = () => {
  return (

    <div className='center'>
      <div className='column'>
      <Menu />
      </div>
      <div className='column'>
      <Content />
      </div>
    </div>

  );}