import { Header } from './header.jsx';
import './App.css';
import { Footer } from './footer.jsx';
import { Center } from './center.jsx';

function App() {
  return (
    <div className="App">
      <Header/>
      <Center/>
      <Footer/>
    </div>
  );
}

export default App;
