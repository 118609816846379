import { useState } from "react";

export const Menu = () => {

const [pasar, setPasar] = useState(true);

  const HandlePasar = () => {
    setPasar(!pasar);
  }

  return (
    <divi>
      <button onClick={HandlePasar}>Home</button>
    </divi>
  );
}
